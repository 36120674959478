'use strict';

import { ajax } from '../../_scripts/commons/ajax'
import Snackbar from 'node-snackbar';

export default class From {
  constructor() {
    $('body').on('submit', 'form:not([data-ajax-form]):not([data-submitted]):not([data-fm-namespace])', function (e) {
      e.preventDefault();

      $(this).data('submitted', true);

      $(this).attr('data-submitted', true);

      $(this).find('[name]').each(function () {
        if (!$(this).val()) {
          $(this).attr('data-name', $(this).attr('name'));

          $(this).attr('name', null);
        }
      });

      const cacheid = window.location.hash.substring(1)

      if (cacheid) {
        $(this).append('<input type="hidden" name="_cacheid" value="' + cacheid + '"/>')
      }

      $(this).submit();
    });

    $('body').on('submit', '[data-ajax-form]', function (e) {
      e.preventDefault();

      ajax.run({
        url: $(this).attr('action'),
        type: $(this).attr('method') || 'get',
        data: $(this).closest('form').serialize()
      }).then(json => {
        Snackbar.show({
          text: json.message,
          pos: 'bottom-center',
          showAction: false
        });
      });

      $(this).closest('.modal').modal('toggle');
    });

    $('body').on('click', '[data-remove-selected]', function (e) {
      const $form = $(this).closest('form')

      $form.find('input[type="checkbox"]').each(function () {
        if ($(this).prop('checked') === true) {
          $(this).trigger('click');
        }
      });

      $('[data-selected-results]').val('[]');
    });

    $(document).on('handlebars.render.end', function () {
      if (!$('[data-selected-results]').length) return;

      const selected = JSON.parse($('[data-selected-results]').val());

      const found = !(!selected.length);

      const $target = $('.detail-button');

      $target[(found ? 'remove' : 'add') + 'Class']('disabled');

      if (found) {
        $target.removeAttr('disabled');
      } else {
        $target.attr('disabled');
      }

      selected.forEach(index => {
        $('input[type="checkbox"][index="' + index + '"]').prop('checked', true);
      });
    })

    $('body').on('input', '[data-year-filter]', function (e) {
      let val = $(this).val();

      val = val.replace(/[^0-9-]/g, '').replace(/(?!^)(-)/g, '');

      $(this).val(val).attr('value', val);
    })

    $('body').on('click', '[data-reset-button]', function (e) {
      const config = $(this).data('toggle-infinite-scroll-params');

      Object.entries(config.params).forEach(([key, value]) => {
        $('[name="' + key + '"]').val(value).trigger('keyup').trigger('input');
      })
    })

    $('body').on('keyup', '[data-year-filter]', function (e) {
      const $parent = $(this).closest('[data-year-parent]');

      let noneEmpty = true;

      $parent.find('[data-year-filter]').each(function () {
        if (!$(this).val()) {
          noneEmpty = false;
        }
      })

      const $button = $(this).closest('.card').find('[data-submit-button]');

      if (noneEmpty) {
        const toggleParams = $button.data('toggle-infinite-scroll-params');

        $parent.find('[data-year-filter]').each(function () {
          toggleParams.params[$(this).attr('name')] = $(this).val();
        })

        $button.data('toggle-infinite-scroll-params', toggleParams);

        $button.removeClass('disabled');
      } else {
        $button.addClass('disabled')
      }
    })

    $('body').on('change', '[data-checkbox-lista-opac]', function (e) {
      let $input = $(this)

      let index = $input.attr('index')

      let val = JSON.parse($('[data-selected-results]').val())

      if ($input.is(':checked')) {
        val.push(index)

        $('[data-selected-results]').val(JSON.stringify(val))
      } else {
        val = val.filter(value => value !== index)

        $('[data-selected-results]').val(JSON.stringify(val))
      }

      const found = !(!JSON.parse($('[data-selected-results]').val()).length);

      const $target = $($(this).data('target'));

      $target[(found ? 'remove' : 'add') + 'Class']('disabled');

      if (found) {
        return $target.removeAttr('disabled');
      }

      return $target.attr('disabled');
    })
  }
}
