'use strict';

import hcSticky from 'hc-sticky';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';

export default class Sticky {
  constructor() {
    ($ => {
      $.extend({
        sticky: {
          stickys: {},
          reset: () => {
            $('.sticky-spacer').remove();

            $('[data-has-sticky]').each(function () {
              $(this).removeAttr('data-has-sticky');
            });

            $.sticky.init();
          },
          init: () => {
            Object.values($.sticky.stickys).forEach(sticky => {
              sticky.refresh();
            });

            $('[data-sticky-item]:not([data-has-sticky])').each(function () {
              const stickyId = uniqueId('sticky_');

              $(this).data('sticky-item', stickyId);

              $(this).attr('data-sticky-item', stickyId);

              $(this).data('has-sticky', true);

              $(this).attr('data-has-sticky', true);

              $(this).closest('[data-sticky-container]').data('sticky-container', stickyId);

              $(this).closest('[data-sticky-container]').attr('data-sticky-container', stickyId);

              $.sticky.stickys[stickyId] = new hcSticky(this, {
                stickTo: '[data-sticky-container="' + stickyId + '"]',
                top: $('.main-header').height(),
                followScroll: false
              });
            });
          }
        }
      });
    })($);

    $.sticky.init();

    $(document).on('handlebars.render.end', debounce($.sticky.init, 100));

    let position = $(window).scrollTop();

    $(window).on('scroll', function () {
      const scroll = $(window).scrollTop();

      const $sticky = $('.results-top-filters[data-sticky-item]');

      let pixels = $sticky.find('.col-12.mb-5').outerHeight(true);

      if (scroll > position && scroll > 660) {
        $sticky.css('margin-top', '-' + pixels + 'px');
      } else {
        $sticky.css('margin-top', 0);
      }

      position = scroll;
    });

    $('body').on('small-menu.changed', function () {
      Object.values($.sticky.stickys).forEach(sticky => {
        sticky.update({
          top: $('.main-header').height()
        });
      });
    });
  }
}
