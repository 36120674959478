'use strict';

import { ajax } from '../../_scripts/commons/ajax';

export default class Chart {
  constructor() {
    (function ($) {
      $.extend({
        chart: {
          presets: {
            area: {
              chart: {
                type: 'area',
              },
              xAxis: {
                gridLineWidth: 1,
                title: null,
                minPadding: 0,
                maxPadding: 0,
                tickInterval: 1
              },
              yAxis: [{
                gridLineWidth: 1,
                lineWidth: 1,
                title: null,
                offset: 0
              }],
              plotOptions: {
                series: {
                  fillOpacity: 0.3
                }
              },
            },
            pie: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              xAxis: {},
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true
                  }
                }
              },
            },
            columns: {
              chart: {
                type: 'column'
              },
              yAxis: [{
                gridLineWidth: 1,
                lineWidth: 1,
                title: null,
                offset: 0
              }, {
                gridLineWidth: 1,
                lineWidth: 1,
                title: null,
                offset: 0,
                opposite: true,
              }],
              xAxis: {
                gridLineWidth: 1,
                title: null,
                minPadding: 0,
                maxPadding: 0,
                tickInterval: 1
              },
              plotOptions: {
                column: {
                  pointPadding: 0,
                  borderWidth: 0
                }
              },
            }
          },
          init: $obj => {
            const chartConfig = $obj.data('chart');

            const config = $.chart.presets[chartConfig.chartType];

            ajax.run({
              url: chartConfig.url
            }).then(json => {
              const coordinates = json.data.map(el => ({
                y: el.value,
                name: el.label
              }));

              const xAxis = config.xAxis;

              const labels = json.data.map(el => el.label);

              xAxis.labels = {
                formatter: function () {
                  return labels[this.value];
                }
              };

              Highcharts.chart($obj.get(0), {
                series: [{
                  name: chartConfig.title,
                  data: coordinates,
                }],
                xAxis,
                exporting: {
                  enabled: false
                },
                legend: {
                  enabled: false
                },
                title: {
                  text: chartConfig.title
                },
                credits: {
                  enabled: false
                },
                ...config
              });
            });
          }
        }
      });
    })($);

    const initChart = () => {
      $('[data-chart]:not([data-has-chart])').each(function () {
        $(this).data('has-chart', true);

        $(this).attr('data-has-chart', true);

        $.chart.init($(this));
      });
    };

    initChart();

    $(document).on('handlebars.render.end', function () {
      initChart();
    });
  }
}
