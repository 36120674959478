'use strict';

import 'leaflet.markercluster';
import { GestureHandling } from 'leaflet-gesture-handling';
import uniqueId from 'lodash/uniqueId'

export default class MapBlock {
  constructor() {
    (function ($) {
      $.extend({
        mapBlock: {
          maps: {},
          init: ($obj) => {
            $obj.data('has-map', true);

            $obj.attr('data-has-map', true);

            const mapConfig = $obj.data('map-config');

            if (!mapConfig.id) {
              mapConfig.id = uniqueId('__map');

              $obj.data('map-config', mapConfig);

              $obj.attr('data-map-config', JSON.stringify(mapConfig));
            }

            const mapCache = $.mapBlock.maps[mapConfig.id] || {
              map: null,
              markers: {}
            }

            if (mapCache.map) {
              return console.log('MapID', mapConfig.id, 'già presente nella pagina');
            }

            L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

            const icon = L.icon({
              iconUrl: sriConfig.marker,
              iconSize: [25, 41],
              iconAnchor: [12.5, 41],
              popupAnchor: [0, -41]
            });

            const markers = new L.MarkerClusterGroup({
              disableClusteringAtZoom: 15
            });

            (mapConfig.markers || []).forEach(marker => {
              const currentMarker = L.marker(marker.coords, {
                icon
              });

              const markerHtml = mapConfig.popupTemplate ? $.handlebars({
                template: mapConfig.popupTemplate,
                context: mapConfig.asFilters ? {
                  info: marker.info,
                  asFilters: true,
                  paramsConfig: {
                    name: mapConfig.name,
                    value: marker.value,
                    infiniteScroll: mapConfig.infiniteScroll
                  }
                } : marker.info,
                returnHtml: true
              }) : marker.info;

              currentMarker.bindPopup(markerHtml);

              mapCache.markers[marker.id || uniqueId('marker_')] = {
                marker: currentMarker,
                coords: marker.coords
              }

              markers.addLayer(currentMarker);
            });

            const mapProperties = {
              zoom: mapConfig.zoom || 13,
              maxZoom: mapConfig.maxZoom || 18,
              layers: markers,
              gestureHandling: true
            };

            if ((mapConfig.markers || []).length === 1) {
              mapProperties.center = mapConfig.markers[0].coords;
            } else {
              mapProperties.center = mapConfig.center
            }

            const map = new L.map($obj.get(0), mapProperties);

            const bounds = [];

            if (mapConfig.showZoomLevel) {
              const ZoomViewer = L.Control.extend({
                onAdd() {
                  const gauge = L.DomUtil.create('div');
                  gauge.style.width = '200px';
                  gauge.style.background = 'rgba(255,255,255,0.5)';
                  gauge.style.textAlign = 'left';
                  map.on('zoomstart zoom zoomend', (ev) => {
                    gauge.innerHTML = `Zoom level: ${map.getZoom()}`;
                  });
                  return gauge;
                }
              });

              (new ZoomViewer()).addTo(map);
            }

            if (!mapConfig.layersMenu) {
              (mapConfig.geoJsons || []).forEach(geoJson => {
                L.geoJSON(geoJson, {
                  onEachFeature: function (feature, layer) {
                    bounds.push(layer.getBounds ? layer.getBounds() : layer.getLatLng());

                    if (feature.info) {
                      const geoJsonHtml = mapConfig.geoJsonPopupTemplate ? $.handlebars({
                        template: mapConfig.geoJsonPopupTemplate,
                        context: feature.info,
                        returnHtml: true
                      }) : '<h1>' + feature.info.title + '</h1><p>' + feature.info.text + '</p>';

                      layer.bindPopup(geoJsonHtml);
                    } else if (feature.properties) {
                      const geoJsonHtml = mapConfig.popupTemplate ? $.handlebars({
                        template: mapConfig.popupTemplate,
                        context: feature.properties,
                        returnHtml: true
                      }) : '<h1>' + feature.properties.title + '</h1><p>' + feature.properties.text + '</p>';

                      layer.bindPopup(geoJsonHtml);
                    }
                  }
                }).addTo(map);
              });
            }

            const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
            }).addTo(map);

            const layers = {};

            if (mapConfig.layersMenu) {
              (mapConfig.geoJsons || []).forEach(geoJson => {
                let layerObj

                const layerGroup = [];

                const currentLayer = L.geoJSON(geoJson, {
                  pointToLayer: function (feature, latlng) {
                    const icon = L.icon({
                      iconUrl: sriConfig.marker,
                      iconSize: [25, 41],
                      iconAnchor: [12, 41],
                      popupAnchor: [1, -34],
                      shadowSize: [41, 41]
                    });

                    return L.marker(latlng, { icon });
                  },
                  style: function (feature) {
                    return { color: geoJson.color };
                  },
                  onEachFeature: function (feature, layer) {
                    if (feature.popup) {
                      const geoJsonHtml = mapConfig.popupTemplate ? $.handlebars({
                        template: mapConfig.popupTemplate,
                        context: feature.popup,
                        returnHtml: true
                      }) : '<h3>[' + feature.popup.id + ']</h3><a href="' + feature.popup.url + feature.popup.id + '">Visualizza</a>';

                      layer.bindPopup(geoJsonHtml);
                    } else if (feature.properties) {
                      const geoJsonHtml = mapConfig.popupTemplate ? $.handlebars({
                        template: mapConfig.popupTemplate,
                        context: feature.properties,
                        returnHtml: true
                      }) : '<h3>[' + feature.properties.id + ']</h3><a href="#' + feature.properties.id_composto + '">Visualizza</a>';

                      layer.bindPopup(geoJsonHtml);
                    }
                  }
                });

                layerGroup.push(currentLayer);

                layerObj = L.layerGroup(layerGroup);

                if (geoJson.visible) {
                  layerObj.addTo(map);
                }

                layers[geoJson.info.title] = layerObj;
              });
            }

            (mapConfig.layers || []).forEach(layer => {
              let layerObj

              if (layer.geoJsons) {
                const layerGroup = [];

                layer.geoJsons.forEach(geoJson => {
                  const currentLayer = L.geoJSON(geoJson, {
                    pointToLayer: function (feature, latlng) {
                      const icon = L.icon({
                        iconUrl: sriConfig.marker,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        shadowSize: [41, 41]
                      });

                      return L.marker(latlng, { icon });
                    },
                    style: function (feature) {
                      return { color: layer.color };
                    },
                    onEachFeature: function (feature, layer) {
                      if (feature.popup) {
                        const geoJsonHtml = mapConfig.popupTemplate ? $.handlebars({
                          template: mapConfig.popupTemplate,
                          context: feature.popup,
                          returnHtml: true
                        }) : '<h3>[' + feature.popup.id + ']</h3><a href="' + feature.popup.url + feature.popup.id + '">Visualizza</a>';

                        layer.bindPopup(geoJsonHtml);
                      }
                    }
                  });

                  if (layer.fitBounds) {
                    bounds.push(currentLayer.getBounds ? currentLayer.getBounds() : currentLayer.getLatLng());
                  }

                  layerGroup.push(currentLayer);
                });

                layerObj = L.layerGroup(layerGroup);
              } else {
                layerObj = L.tileLayer.wms(layer.url, layer.config);
              }

              if (layer.visible) {
                layerObj.addTo(map);
              }

              layers[layer.name] = layerObj;
            });

            const kmz = L.kmzLayer().addTo(map);

            (mapConfig.kmls || []).forEach(kml => {
              kmz.load(kml);
            });

            if (Object.keys(layers).length) {
              var baseMaps = {
                'OSM': osm,
              };

              L.control.layers(baseMaps, layers).addTo(map);
            }

            if (mapConfig.showSearch) {
              map.addControl(new L.Control.Search({
                url: 'https://nominatim.openstreetmap.org/search?format=json&q={s}',
                jsonpParam: 'json_callback',
                propertyName: 'display_name',
                propertyLoc: ['lat', 'lon'],
                marker: L.circleMarker([0, 0], { radius: 30 }),
                autoCollapse: true,
                autoType: false,
                minLength: 2
              }));
            }

            if (mapConfig.showScale) {
              L.control.scale({ imperial: false }).addTo(map);
            }

            if ((mapConfig.markers || []).length > 1 && !mapConfig.preventMarkersFit) {
              bounds.push(...(mapConfig.markers || []).map(marker => {
                return marker.coords;
              }));
            }

            if (bounds.length > 0 && !mapConfig.preventMarkersFit) {
              map.fitBounds(bounds);
            }

            if (mapConfig.preventMarkersFit && mapConfig.bounds) {
              map.fitBounds(mapConfig.bounds);
            }

            mapCache.map = map;

            $.mapBlock.maps[mapConfig.id] = mapCache;
          },
        }
      });
    })($);

    $('[data-map-config]:not([data-has-map])').each(function () {
      $.mapBlock.init($(this));
    });

    $(document).on('handlebars.render.end', function () {
      $('[data-map-config]:not([data-has-map])').each(function () {
        $.mapBlock.init($(this));
      });
    });

    $('body').on('click', '[data-map-anchor]', function (e) {
      e.preventDefault();

      const mapAnchorConfig = $(this).data('map-anchor');

      const currentMap = $.mapBlock.maps[mapAnchorConfig.mapId] || {};

      if (currentMap.map) {
        const marker = currentMap.markers[mapAnchorConfig.markerId] || null;

        $('body, html').animate({
          scrollTop: $(currentMap.map._container).offset().top - $('.main-header').outerHeight()
        }, 250);

        if (marker) {
          currentMap.map.once('moveend', () => {
            marker.marker.openPopup();
          });

          currentMap.map.setView(marker.coords, 15);
        }
      }
    });

    $('body').on('hide.bs.modal', '.modal', function () {
      const $mapBlock = $(this).find('[data-map-config]');

      if ($mapBlock.length) {
        const mapConfig = $mapBlock.data('map-config') || {};

        const map = $.mapBlock.maps[mapConfig.id];

        map.map.remove();

        delete $.mapBlock.maps[mapConfig.id];
      }
    });

    $('body').on('shown.bs.modal', '.modal', function () {
      const $mapBlock = $(this).find('[data-map-config]');

      if ($mapBlock.length) {
        Object.values($.mapBlock.maps).forEach(map => {
          map.map.invalidateSize()
        });
      }
    });

    $(window).on('hashchange', function () {
      if ($('[data-manage-collapse]').length) {
        let id = window.location.hash;

        $('.collapse.show').removeClass('show');

        $('[data-toggle="collapse"]').addClass('collapsed').attr('aria-expanded', false);

        while (id) {
          const target = $(id).attr('href') || $(id).attr('data-target');

          $(id).removeClass('collapsed').attr('aria-expanded', true);

          $(target).addClass('show');

          id = id.split('_');

          id.pop();

          id = id.join('_');
        }

        $(window).scrollTop($(window.location.hash).offset().top - 150);
      }
    })
  }
}
